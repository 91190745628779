import React from "react"
import { graphql } from "gatsby"

import { TransitionGroup} from 'react-transition-group'

import Seo from "../components/seo"
import { Container } from "../grid"
import { ArchiveSingle } from "../components/dialogi/ArchiveSingle"

import { Box } from "@mui/system"
import { Grow } from '@mui/material';

const DialogiArchive = ({pageContext, data}) => {
  // console.log(pageContext)
  //console.log(data)
  const dialogi = data.dialogi.edges.sort((a, b) => a.node.stevilka.split('–')[0] - b.node.stevilka.split('–')[0])
  // console.log(dialogi)

  return (
    <>
      <Seo title="Arhiv številk" />
      <Box sx = { (theme) => ({
        bgcolor: theme.palette.white
      })}>
        <Container pb={{
          lg: 14
        }}>
          <Box sx = { (theme) => ({
            '&>div':{
              justifyContent: 'center',
              display: 'grid',
              columnGap: {
                xs: theme.spacing(4),
                xl: theme.spacing(8)
              },
              rowGap:{
                xs: theme.spacing(8),
                md: theme.spacing(10)
              },
              gridTemplateColumns: {
                xs: '1fr 1fr',
                md2: '1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr'
              }
            }
          })}>
          <TransitionGroup>
              {dialogi.map((issue,i) => (
                <Grow 
                  key={`${issue.node.id}-${i}`}
                  timeout={{
                    enter: 500,
                    appear: 500,
                    exit: 500,
                  }}
                >
                  <ArchiveSingle book={issue.node}/>
                </Grow>
              ))}
            </TransitionGroup>
          </Box>
        </Container>
      </Box>

    </>
  )
}

export default DialogiArchive

export const query = graphql`
  query($year: Date){
    dialogi: allStrapiDialogi(
      filter: {leto: {year: {eq: $year}}}
      sort: {fields: updatedAt}
    ) {
    edges {
      node {
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1024)
            }
          }
        }
        leto {
          color
          year
          number
        }
        id
        strapi_id
        pdf {
          url
        }
        prices {
          online
          regular
          discount
        }
        stevilka
        title
      }
    }
  }
  }
`